import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Alert } from '@grafana/ui';

import { useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { InstallCard } from 'components/InstallCard';
import { ViewYourDashboardsSection } from 'features/agent-integrations/ViewYourDashboards/ViewYourDashboardsSection';
import { useApiToken } from 'hooks/useApiToken';
import { GRAFANA_EXAMPLE_API } from 'utils/consts';

import { encodeToBase64, openLitConfiguration } from './snippets';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    'ol li': {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
    },
    pre: {
      width: '60%',
    },
  }),
  instructionSubheader: css({
    fontSize: '14px',
    fontStyle: 'italic',
    marginBottom: '10px',
  }),
  instructions: css({
    listStyleType: 'none',
  }),
  instructionsList: css({
    marginLeft: '30px',
    '& li': {
      marginBottom: '5px',
    },
  }),
  instructionNote: css({
    color: theme.colors.text.secondary,
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '10px',
  }),
  h3: css({
    marginTop: '40px',
  }),
  apiGroup: css({
    width: '700px',
  }),
  link: css({
    cursor: 'pointer',
    textDecoration: 'underline',
  }),
});

export const AIObservabilityInstructions: FC = () => {
  const { token } = useApiToken();
  const [showAlert, setShowAlert] = React.useState(true);
  const styles = useStyles2(getStyles);
  const successMessage = 'Your API key has been generated below!';
  const { hmInstancePromId } = useHostedDataDetailsWithFallback();
  const encodedToken = token ? encodeToBase64(token) : undefined;

  return (
    <div>
      <div className={styles.container}>
        {showAlert ? (
          <Alert
            title="Public Preview: LLM Application Monitoring"
            severity="info"
            onRemove={() => setShowAlert(false)}
          >
            <div>
              This integration is in{' '}
              <a
                className={styles.link}
                target="_blank"
                rel="noreferrer"
                href="https://grafana.com/docs/release-life-cycle/"
              >
                public preview
              </a>
              . Thank you for trying it out!
            </div>
          </Alert>
        ) : null}
        <h1>LLM Application Monitoring with OpenTelemetry</h1>

        <h3 className={styles.h3}>Obtain environment variables</h3>
        <div className={styles.instructionSubheader}>
          Setting these environment variables configures the SDK with the necessary endpoint and headers to securely
          send telemetry data to Grafana Cloud.
          <br />
          To get these variables, Follow the below steps
        </div>
        <ol className={styles.instructionsList}>
          <li>Sign in to Grafana Cloud Portal and select your Grafana Cloud Stack</li>
          <li>Click Configure in the OpenTelemetry section</li>
          <li>Copy the OTLP Endpoint URL</li>
          <li>Copy and Save the value for `OTEL_EXPORTER_OTLP_ENDPOINT`</li>
        </ol>
        <div className={styles.instructionNote}>
          Note: Replace the space after Basic with %20: OTEL_EXPORTER_OTLP_HEADERS=“Authorization=Basic%20[base64
          instanceID:token]”
        </div>

        <h3 className={styles.h3}>
          Install <code>openlit </code>Library
        </h3>
        <pre>pip install openlit</pre>
        <h3 className={styles.h3}>Create Grafana Cloud Token</h3>
        <p>
          A Grafana Cloud token is necessary for authentication when sending telemetry data to Grafana Cloud. The
          provided token should be kept secure and used in your application&lsquo;s environment variables for
          authorization.
        </p>
        <div className={styles.apiGroup}>
          <p>Generate Grafana Cloud Token</p>
          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={hmInstancePromId}
            successMessage={successMessage}
          />
        </div>
        <h3 className={styles.h3}>Add Environment Variables</h3>
        <pre>{openLitConfiguration(encodedToken)}</pre>
        <h3 className={styles.h3}>Instrument your code</h3>
        <p>
          To begin collecting telemetry data, initialize the `openlit` library at the start of your application. This
          simple step hooks into your application to start monitoring its performance and behavior.
        </p>
        <pre>
          import openlit{'\n'}
          openlit.init()
        </pre>
        <div>
          <InstallCard />
        </div>
        <hr />
        <ol className={styles.instructions}>
          <ViewYourDashboardsSection />
        </ol>
      </div>
    </div>
  );
};
